import React, { useEffect, useState, useMemo } from 'react';
// routes
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

const getRolesFromSessionStorage = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  if (user && user.roles) {
    return user.roles; // roles está almacenado como una cadena JSON
  }
  return [];
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const navigate = useNavigate(); // Para redireccionar al usuario
  const [userRoles, setUserRoles] = useState([]);

  // useEffect(() => {
  //   const roles = getRolesFromSessionStorage();
  //   setUserRoles(roles);
  // }, []);

  useEffect(() => {
    const roles = getRolesFromSessionStorage();
    setUserRoles(roles);

    // Redirigir al panel posgraduante si tiene el rol "posgraduante"
    if (roles.includes('posgraduante')) {
      navigate(paths.dashboard.tramites_usuarios.root); // Redirige a la página de trámites
    }
  }, [navigate]);

  // const isSecretaria = useMemo(() => userRoles.includes('secretaria'), [userRoles]);
  const isTecnicoAdministrativo = useMemo(
    () => userRoles.includes('tecnico_administrativo'),
    [userRoles]
  );
  const isPosgraduante = useMemo(() => userRoles.includes('posgraduante'), [userRoles]);

  const data = useMemo(
    () =>
      isPosgraduante
        ? [
            {
              subheader: t('PANEL POSGRADUANTE'),
              items: [
                {
                  title: t('Todos Mis Trámites'),
                  path: paths.dashboard.tramites_usuarios.root,
                  icon: ICONS.dashboard,
                },
              ],
            },
          ]
        : [
            // OVERVIEW
            {
              subheader: t('PANEL PRINCIPAL'),
              items: [
                {
                  title: t('Inicio'),
                  path: paths.dashboard.root,
                  icon: ICONS.dashboard,
                },
              ],
            },

            // {
            //   subheader: t('PANEL PRINCIPAL USUARIOS'),
            //   items: [
            //     {
            //       title: t('Todos Mis Trámites'),
            //       path: paths.dashboard.tramites_usuarios.root,
            //       icon: ICONS.dashboard,
            //     },
            //   ],
            // },

            !isTecnicoAdministrativo && {
              subheader: t('HOJA DE RUTA'),
              items: [
                {
                  title: t('Hojas de ruta'),
                  path: paths.dashboard.tray.root,
                  icon: ICONS.product,
                  children: [
                    { title: t('Crear Hoja Ruta'), path: paths.dashboard.tray.mis_tramites },
                    { title: t('Lista de Tramites'), path: paths.dashboard.tray.earrings },
                  ].filter(Boolean),
                },
              ],
            },

            // {
            //   subheader: t('DOCUMENTOS'),
            //   items: [
            //     {
            //       title: t('Documentos'),
            //       path: paths.dashboard.documentos.root,
            //       icon: ICONS.blog,
            //       children: [{ title: t('Mis documentos'), path: paths.dashboard.documentos.root }],
            //     },
            //   ],
            // },

            !isTecnicoAdministrativo && {
              subheader: t('TRAMITES'),
              items: [
                {
                  title: t('Agregar tramite y requisito'),
                  path: paths.dashboard.tramitesrequisitos.root,
                  icon: ICONS.blog,
                  children: [{ title: t('nuevo'), path: paths.dashboard.tramitesrequisitos.root }],
                },
              ],
            },

            !isTecnicoAdministrativo && {
              subheader: t('PROCESOS'),
              items: [
                {
                  title: t('Procesos'),
                  path: paths.dashboard.procesos.root,
                  icon: ICONS.blog,
                  children: [{ title: t('nuevo'), path: paths.dashboard.procesos.root }],
                },
              ],
            },

            isTecnicoAdministrativo && {
              subheader: t('HOJA DE RUTA ADMINISTRATIVOS'),
              items: [
                {
                  title: t('Hoja de ruta'),
                  path: paths.dashboard.hoja_de_ruta_usuario.root,
                  icon: ICONS.blog,
                  children: [
                    {
                      title: t('Listado Tramites'),
                      path: paths.dashboard.hoja_de_ruta_usuario.listado,
                    },
                  ],
                },
              ],
            },
          ].filter(Boolean),
    [t, isTecnicoAdministrativo, isPosgraduante]
  );

  // const data = useMemo(
  //   () =>
  //     [
  //       // OVERVIEW
  //       // ----------------------------------------------------------------------
  //       {
  //         subheader: t('PANEL PRINCIPAL'),
  //         items: [
  //           {
  //             title: t('Inicio'),
  //             path: paths.dashboard.root,
  //             icon: ICONS.dashboard,
  //           },
  //         ],
  //       },

  //       {
  //         subheader: t('PANEL POSGRADUANTE'),
  //         items: [
  //           {
  //             title: t('Todos Mis Trámites'),
  //             path: paths.dashboard.tramites_usuarios.root,
  //             icon: ICONS.dashboard,
  //           },
  //         ],
  //       },

  //       // !isTecnicoAdministrativo && {
  //       //   subheader: t('Area/Oficinas'),
  //       //   items: [
  //       //     {
  //       //       title: t('Area/Oficinas'),
  //       //       path: paths.dashboard.area.root,
  //       //       icon: ICONS.blog,
  //       //       children: [
  //       //         { title: t('Oficinas'), path: paths.dashboard.area.root },
  //       //         { title: t('Agregar'), path: paths.dashboard.area.new },
  //       //       ],
  //       //     },
  //       //   ],
  //       // },

  //       // BANDEJA
  //       !isTecnicoAdministrativo && {
  //         subheader: t('HOJA DE RUTA'),
  //         items: [
  //           {
  //             title: t('Hojas de ruta'),
  //             path: paths.dashboard.tray.root,
  //             icon: ICONS.product,
  //             children: [
  //               // { title: t('Agregar tramite'), path: paths.dashboard.tray.agregar },
  //               { title: t('Crear Hoja Ruta'), path: paths.dashboard.tray.mis_tramites },
  //               { title: t('Lista de Tramites'), path: paths.dashboard.tray.earrings },
  //             ].filter(Boolean),
  //           },
  //         ],
  //       },

  //       {
  //         subheader: t('DOCUMENTOS'),
  //         items: [
  //           {
  //             title: t('Documentos'),
  //             path: paths.dashboard.documentos.root,
  //             icon: ICONS.blog,
  //             children: [{ title: t('Mis documentos'), path: paths.dashboard.documentos.root }],
  //           },
  //         ],
  //       },

  //       // hoja de ruta
  //       // !isTecnicoAdministrativo && {
  //       //   subheader: t('HOJA DE RUTAS'),
  //       //   items: [
  //       //     {
  //       //       title: t('Hoja de ruta'),
  //       //       path: paths.dashboard.hoja_de_ruta.root,
  //       //       icon: ICONS.blog,
  //       //       children: [
  //       //         { title: t('ruta'), path: paths.dashboard.hoja_de_ruta.root },
  //       //         { title: t('sss'), path: paths.dashboard.hoja_de_ruta.new },
  //       //       ],
  //       //     },
  //       //   ],
  //       // },
  //       // TRAMITES Y REQUISITOS

  //       !isTecnicoAdministrativo && {
  //         subheader: t('TRAMITES'),
  //         items: [
  //           {
  //             title: t('Agregar tramite y requisito'),
  //             path: paths.dashboard.tramitesrequisitos.root,
  //             icon: ICONS.blog,
  //             children: [{ title: t('nuevo'), path: paths.dashboard.tramitesrequisitos.root }],
  //           },
  //         ],
  //       },

  //       // HOJA DE RUTA USUARIOS
  //       isTecnicoAdministrativo && {
  //         subheader: t('HOJA DE RUTA ADMINISTRATIVOS'),
  //         items: [
  //           {
  //             title: t('Hoja de ruta'),
  //             path: paths.dashboard.hoja_de_ruta_usuario.root,
  //             icon: ICONS.blog,
  //             children: [
  //               // { title: t('Crear Hoja Ruta'), path: paths.dashboard.hoja_de_ruta_usuario.root },
  //               {
  //                 title: t('Listado Tramites'),
  //                 path: paths.dashboard.hoja_de_ruta_usuario.listado,
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ].filter(Boolean),
  //   [t, isTecnicoAdministrativo, isPosgraduante]
  // );

  return data;
}
