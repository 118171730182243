import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const NuevaLegalizacion = lazy(() => import('src/pages/legalizaciones/new'));
// const IniciarNuevaSolicitud = lazy(() => import('src/pages/legalizaciones/foundation/typography'));

// ----------------------------------------------------------------------

export const nuevoRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),

    path: 'nuevo',
    children: [{ element: <NuevaLegalizacion />, index: true }],
  },
];
