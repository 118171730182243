import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// config
// import { PATH_AFTER_LOGIN } from 'src/config-global';
//
import { mainRoutes, HomePage } from './main';
import { authRoutes } from './auth';
import { authDemoRoutes } from './auth-demo';
import { dashboardRoutes } from './dashboard';
import { componentsRoutes } from './components';
import { tramitesRoutes } from './tramites';
import { seguimientoRoutes } from './seguimiento';
import { legalizacionesRoutes } from './legalizaciones';
import { nuevoRoutes } from './nuevo';
import { nuevoTramiteRoutes } from './nuevo_tramite';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Establecer página de índice con saltar página de inicio
    // {
    //   path: '/',
    //   element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    // },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    {
      path: '/',
      element: (
        <MainLayout>
          <HomePage />
        </MainLayout>
      ),
    },

    // Auth routes
    ...authRoutes,
    ...authDemoRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    ...componentsRoutes,

    // Tramites routes
    ...tramitesRoutes,
    ...nuevoTramiteRoutes,
    ...seguimientoRoutes,

    // Legalizaciones routes
    ...legalizacionesRoutes,

    // Nuevo routes
    ...nuevoRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
