// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';

import { useContext } from 'react';
import { AuthContext } from 'src/auth/context/jwt';
// components
import Label from 'src/components/label';

// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { userMock } = useMockedUser();

  const { user } = useContext(AuthContext);

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar
            src={userMock?.photoURL}
            alt={user?.nombre_completo?.nombre}
            sx={{ width: 48, height: 48 }}
          />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            Usuario
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.nombre_completo?.nombre} {user?.nombre_completo?.paterno}{' '}
            {user?.nombre_completo?.materno}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.usuario}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.roles[1]}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
