import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { m, useScroll } from 'framer-motion';

// routes
import { RouterLink } from 'src/routes/components';

// componentes
import { MotionContainer, varFade } from 'src/components/animate';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  // Define la lista de plataformas
  const platforms = ['posgrado'];

  if (disabledLink) {
    return (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 60,
          height: 60,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <Stack spacing={2} direction="row" justifyContent="center">
          {platforms.map((platform) => (
            <m.div key={platform} variants={varFade().in}>
              <Box
                component="img"
                src={`/assets/icons/platforms/ic_${platform}.png`}
                sx={{ width: 60, height: 60 }}
              />
            </m.div>
          ))}
        </Stack>
      </Box>
    );
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      <Stack spacing={2} direction="row" justifyContent="center">
        {platforms.map((platform) => (
          <m.div key={platform} variants={varFade().in}>
            <Box
              component="img"
              src={`/assets/icons/platforms/ic_${platform}.png`}
              sx={{ width: 70, height: 45 }}
            />
          </m.div>
        ))}
      </Stack>
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
