import { useContext } from 'react';
//
import { AuthContext } from '../context/jwt/auth-context';

// ----------------------------------------------------------------------

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) throw new Error('El contexto useAuthContext debe usarse dentro de AuthProvider');

  return context;
};
