// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API;
export const HOST_API_CORRESPONDENCIA = process.env.REACT_APP_HOST_API_CORRESPONDENCIA;
export const HOST_API_TRAMITES = process.env.REACT_APP_HOST_API_TRAMITES;

export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const ASSETS_API_CORRESPONDENCIA = process.env.REACT_APP_ASSETS_API_CORRESPONDENCIA;
export const ASSETS_API_TRAMITES = process.env.REACT_APP_ASSETS_API_TRAMITES;

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'

// CORRESPONDENDIA
export const PATH_AFTER_CORRESPONDENCIA_LOGIN = paths.dashboard.root; // al dashboard
