import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// components
import { SplashScreen } from 'src/components/loading-screen';

const VistaIndex = lazy(() => import('src/pages/seguimiento'));

export const seguimientoRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),

    path: 'seguimiento',
    children: [{ element: <VistaIndex />, index: true }],
  },
];
