import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const NuevoTramite = lazy(() => import('src/pages/tramites/new'));
// const IniciarNuevaSolicitud = lazy(() => import('src/pages/legalizaciones/foundation/typography'));

// ----------------------------------------------------------------------

export const nuevoTramiteRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),

    path: 'nuevo_tramite',
    children: [{ element: <NuevoTramite />, index: true }],
  },
];
